import {logMessage} from './helpers';
export class Requester {
  constructor(headers) {
    if (headers) {
      this._headers = headers;
    }
  }
  sendRequest(datafeedUrl, urlPath, params, token = null, scannerTitle=null) {
    if (params !== undefined) {
      const paramKeys = Object.keys(params);
      if (paramKeys.length !== 0) {
        urlPath += '?';
      }
      urlPath += paramKeys
        .map((key) => {
          if (key === 'token' && params[key] === undefined) {
            if (token) {
                return `${encodeURIComponent('token')}=${encodeURIComponent(token.toString())}`;
            } 
            return `${encodeURIComponent('token')}=${encodeURIComponent((null).toString())}`;
          }
          if ((key === 'scannerTitle' || key === 'scanner') && params[key] === undefined) {
            if (scannerTitle) {
                return `${encodeURIComponent('scanner')}=${encodeURIComponent(scannerTitle.toString())}`;
            } 
            return `${encodeURIComponent('scanner')}=${encodeURIComponent((null).toString())}`;
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`;
        })
        .join('&');
    } else if (token || scannerTitle) {
      urlPath += `?`;
      if (token) {
        urlPath += `${encodeURIComponent('token')}=${encodeURIComponent(token.toString())}`;
      }
      if (scannerTitle) {
        urlPath += `&${encodeURIComponent('scanner')}=${encodeURIComponent(scannerTitle.toString())}`;
      }
    }
    logMessage('New request: ' + urlPath);
    // Send user cookies if the URL is on the same origin as the calling script.
    const options = {credentials: 'same-origin'};
    if (this._headers !== undefined) {
      options.headers = this._headers;
    }
    // eslint-disable-next-line no-restricted-globals
    return fetch(`${datafeedUrl}/${urlPath}`, options)
      .then((response) => response.text())
      .then((responseTest) => JSON.parse(responseTest));
  }
}
