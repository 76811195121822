/* eslint-disable no-underscore-dangle */
/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
import {UDFCompatibleDatafeedBaseWithStreaming} from './datafeedBase';
import {QuotesProvider} from './lib/quotes-provider';
import {Requester} from './lib/requester';
import {HistoryProvider} from './lib/history-provider';

function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setMinutes(date.getMinutes() + 1);
  return date.getTime();
}

export class UDFCompatibleDatafeedWithStreaming extends UDFCompatibleDatafeedBaseWithStreaming {
  constructor(datafeedURL, wsSocket, tk, layout = null, scannerTitle = null, supportedResolutions = [], updateFrequency = 30 * 1000, limitedServerResponse, historyURL = null) {
    const requester = new Requester();
    const quotesProvider = new QuotesProvider(datafeedURL, requester);
    const url = new URL(window.location.href);
    let token = tk ?? url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso') ?? null;
    }

    super(datafeedURL, quotesProvider, requester, updateFrequency, limitedServerResponse, token, scannerTitle, supportedResolutions, historyURL);

    this._token = null;
    if (token) {
      this._token = token;
    }
    this._scannerTitle = null;
    if (scannerTitle) {
      this._scannerTitle = scannerTitle;
    }
    this.socket = wsSocket;
    this._requester = requester;
    this._historyProvider = new HistoryProvider(historyURL, this._requester, limitedServerResponse, token, scannerTitle);

    this.channelToSubscription = new Map();
    this.lastBarsCache = new Map();
    window.channelToSubscription = this.channelToSubscription;
    window.lastBarsCache = this.lastBarsCache;

    // if (!layout || layout !== 'mw-layout') {
    //   console.log("[socket] Subscribing to chart-update")
    //   const handleMessage = this.handleMessage.bind(this);
    //   this.socket.on('chart-update', handleMessage);
    // }

    this.layout = layout;
  }

  onReady(callback) {
    console.log('[onReady]: Method call');
    super.onReady(callback);
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    console.log('[searchSymbols]: Method call');
    super.searchSymbols(userInput, exchange, symbolType, onResultReadyCallback, this._token, this._scannerTitle);
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) {
    super.resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension, this._token, this._scannerTitle);
  }

  getBars(symbolInfo, resolution, periodParams, onResult, onError) {
    this._historyProvider
      .getBars(symbolInfo, resolution, periodParams, this._token, this._scannerTitle)
      .then((result) => {
        const {firstDataRequest} = periodParams;
        const {bars} = result;
        if (firstDataRequest) {
          const lastBar = {...bars[bars.length - 1]};
          this.lastBarsCache.set(`${symbolInfo.name}`, lastBar);
        }
        onResult(result.bars, result.meta);
      })
      .catch(onError);
  }

  subscribeBars(symbolInfo, resolution, onTick, listenerGuid, onResetCacheNeededCallback) {
    // console.log('[subscribeBars]: Method call with listenerGuid:', listenerGuid);
    this._dataPulseProvider.subscribeBars(
      symbolInfo,
      resolution,
      onTick,
      listenerGuid,
      this.socket,
      onResetCacheNeededCallback,
      this.lastBarsCache.get(`${symbolInfo.name}`),
      this.channelToSubscription,
      this.layout,
    );
  }

  unsubscribeBars(listenerGuid) {
    // console.log('[unsubscribeBars]: Method call with listenerGuid:', listenerGuid);
    this._dataPulseProvider.unsubscribeBars(listenerGuid, this.socket, this.channelToSubscription, this.layout);
  }

    // eslint-disable-next-line class-methods-use-this
    async getLatestBarFromChart() {
      if (window.tvWidget) {
        const nextMin = new Date().setMinutes(new Date().getMinutes() + 1);
        const nextMinValue = new Date(nextMin).getTime() / 1000;
        const data = await window.tvWidget
          .activeChart()
          .exportData({includeTime: true, includedStudies: [], to: nextMinValue});
        if (!data?.data?.length) {
          return null;
        }
        const latestBar = data.data[data.data.length - 1];
        return latestBar;
      }
      return null;
    }

    // eslint-disable-next-line class-methods-use-this
    async getCurrentResolution() {
      if (window.tvWidget) {
        const resolution = await window.tvWidget.activeChart().resolution();
        return resolution;
      }
      return null;
    }
  
    async handleMessage(tick) {
      if (!tick) {
        return;
      }
      const currentResolution = await this.getCurrentResolution();
      const {data, resolution} = tick ?? {data: null, resolution: '1'};
      if (!data) {
        return;
      }
      if (currentResolution !== resolution) {
        console.log('[socket] Resolution is different, not updating')
        return;
      }
      const {barData, meta} = data;
      const channelString = `${meta?.ticker}`;
  
      if (!channelString) {
        return;
      }
      console.log(`[socket] Message: Updating ${channelString}`, tick);
  
      const subscriptionItem = this.channelToSubscription.get(channelString);
  
      if (subscriptionItem === undefined) {
        return;
      }
      this.getLatestBarFromChart().then((latestBar) => {
        if (!latestBar) {
          return;
        }
        const {lastDailyBar} = subscriptionItem;
        const nextDailyBarTime = getNextDailyBarTime(latestBar[0]);
        const {o, h, l, c, t, v} = barData;
  
        let bar;
        const tradeTime = Number(t) * 1000;
        const latestBarTime = new Date(latestBar[0] * 1000);
        const latestBarValue = latestBarTime.getTime();
  
        if (tradeTime >= nextDailyBarTime) {
          bar = {
            time: nextDailyBarTime,
            open: o,
            high: h,
            low: l,
            close: c,
            volume: v,
            t: nextDailyBarTime,
            o,
            h,
            l,
            c,
            v,
          };
  
          console.log('[socket] Generate new bar', bar);
          subscriptionItem.lastDailyBar = bar;
          subscriptionItem.handlers.forEach((handler) => {
            handler.callback(bar);
          });
        } else {
          const lastOpen = latestBar[1];
          const lastHigh = latestBar[2];
          const lastLow = latestBar[3];
          const lastClose = latestBar[4];

          if (lastOpen && lastHigh && lastLow && lastClose && lastOpen === o && lastLow === l && lastHigh === h && lastClose === c) {
            console.log('[socket] values are same, not updating');
            return;
          }
          bar = {
            ...lastDailyBar,
            o,
            l,
            c,
            h,
            t: latestBarValue,
            open: o,
            high: h,
            low: l,
            close: c,
            time: latestBarValue,
          };
          subscriptionItem.lastDailyBar = bar;
          console.log('[socket] Update the latest bar by price', barData.h);
          subscriptionItem.handlers.forEach((handler) => {
            handler.callback(bar);
          });
        }
      });
  
      // const {lastDailyBar} = subscriptionItem;
      // const nextDailyBarTime = getNextDailyBarTime(lastDailyBar?.t ?? lastDailyBar?.time);
      // const {o, h, l, c, t, v} = barData;
  
      // let bar;
      // const tradeTime = Number(t) * 1000;
      // // console.log('tradeTime >= nextDailyBarTime', tradeTime, nextDailyBarTime, lastDailyBar?.t ?? lastDailyBar?.time, tradeTime >= nextDailyBarTime);
  
      // if (tradeTime >= nextDailyBarTime) {
      //   bar = {
      //     time: nextDailyBarTime,
      //     open: o,
      //     high: h,
      //     low: l,
      //     close: c,
      //     volume: v,
      //     t: nextDailyBarTime,
      //     o,
      //     h,
      //     l,
      //     c,
      //     v,
      //   };
  
      //   console.log('[socket] Generate new bar', bar);
      //   subscriptionItem.lastDailyBar = bar;
      //   subscriptionItem.handlers.forEach((handler) => {
      //     // console.log('handler', handler);
      //     handler.callback(bar);
      //   });
      // } else {
      //   bar = {
      //     ...lastDailyBar,
      //     o: Math.max(lastDailyBar.high, h),
      //     l: Math.min(lastDailyBar.low, l),
      //     c,
      //     open: Math.max(lastDailyBar.high, h),
      //     low: Math.min(lastDailyBar.low, l),
      //     close: c,
      //   };
      //   console.log('[socket] Update the latest bar by price', barData.h);
      //   // subscriptionItem.lastDailyBar = bar;
      //   subscriptionItem.handlers.forEach((handler) => {
      //     // console.log('handler', handler);
      //     // handler.callback(bar);
      //     // handler.resetCache(bar);
      //     // if (window.tvWidget) {
      //     //   window.tvWidget.chart().resetData();
      //     // }
      //   });
      // }
    }
}
