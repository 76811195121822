import React, {useState, useEffect, useContext} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Modal from 'react-modal';
import {useParams, useOutletContext} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
// import Chart from '../Components/Chart/Chart';
import TradingViewChart from '../Components/Chart/TradingViewChart';
import OptionView from '../Components/PopOut/OptionView';
import Watchlist from '../Components/Watchlist/Watchlist';
import AppContext from '../Context/AppContext';

const customStylesOptions = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const customStylesLocked = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const customStylesUpcoming = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const MWViewContent = ({
  scanners,
  optionsColumns,
  optionsAllowed,
  lockedColumns,
  hasColumnsToChart,
  columnsToChart,
  supportedResolutions,
  scannerTitle,
  timeFrames,
  displayChartDisabledMessage,
  allowCustomInputWatchlist,
  symbolToChart,
  groupToChart,
  groups,
  groupCollection,
}) => {
  if (!scanners?.length) {
    return null;
  }
  const [, , wsSocket] = useOutletContext();
  const {theme, scannerLayout} = useContext(AppContext);
  const [mainGroup, setMainGroup] = useState(null);
  const [secondaryGroup, setSecondaryGroup] = useState(null);

  useEffect(() => {
    if (!scanners?.length) {
      return;
    }
    if (scanners?.length === 2) {
      if (!isEqual(scanners[0], mainGroup)) {
        setMainGroup(scanners[0]);
      }
      if (!isEqual(scanners[1], secondaryGroup)) {
        setSecondaryGroup(scanners[1]);
      }
    }
  }, [scanners]);

  if (!mainGroup || !secondaryGroup) {
    return null;
  }
  return (
    <div className="mw-scanner">
      <div className="mw-scanner-main">
        {mainGroup?.allowedWatchlist ? (
          <Tabs>
            <TabList>
              <Tab>{mainGroup.group}</Tab>
              <Tab>Personal Watchlist</Tab>
            </TabList>
            <TabPanel>
              <Scanner
                group={groupCollection[mainGroup.group] ?? null}
                groupNumber={groups.length}
                multiScreen={Object.keys(groupCollection || {}).length > 1}
                optionsColumns={optionsColumns}
                optionsAllowed={optionsAllowed}
                lockedColumns={lockedColumns}
                groupName={mainGroup.group}
                displayChartDisabledMessage={displayChartDisabledMessage}
              />
            </TabPanel>
            <TabPanel>
              <Watchlist
                optionsColumns={optionsColumns}
                lockedColumns={lockedColumns}
                optionsAllowed={optionsAllowed}
                allowedChart={mainGroup?.allowedChart}
                allowCustomInputWatchlist={allowCustomInputWatchlist}
              />
            </TabPanel>
          </Tabs>
        ) : (
          <Scanner
            group={groupCollection[mainGroup.group] ?? null}
            groupNumber={groups.length}
            multiScreen={Object.keys(groupCollection || {}).length > 1}
            optionsColumns={optionsColumns}
            optionsAllowed={optionsAllowed}
            lockedColumns={lockedColumns}
            groupName={mainGroup.group}
            displayChartDisabledMessage={displayChartDisabledMessage}
          />
        )}
      </div>
      <div className="mw-scanner-secondary">
        <div className="mw-scanner-chart">
          {groupToChart ?? mainGroup.group ? (
            <TradingViewChart
              symbolToChart={symbolToChart}
              groupToChart={groupToChart ?? mainGroup.group ?? null}
              groupCollection={groupCollection}
              theme={theme}
              wsSocket={wsSocket}
              layout={scannerLayout}
              hasColumnsToChart={hasColumnsToChart}
              columnsToChart={columnsToChart}
              supportedResolutions={supportedResolutions}
              scannerTitle={scannerTitle}
              timeFrames={timeFrames}
            />
          ) : (
            <span />
          )}
        </div>
        <div className="mw-scanner-secondary-scanner">
          <Scanner
            group={groupCollection[secondaryGroup.group] ?? null}
            groupNumber={groups.length}
            multiScreen={Object.keys(groupCollection || {}).length > 1}
            optionsColumns={optionsColumns}
            optionsAllowed={optionsAllowed}
            lockedColumns={lockedColumns}
            groupName={secondaryGroup.group}
            displayChartDisabledMessage={displayChartDisabledMessage}
          />
        </div>
      </div>
    </div>
  );
};

const MWViewContentMemo = React.memo(MWViewContent, (prev, next) => {
  const groupCollectionSame = JSON.stringify(prev?.groupCollection) === JSON.stringify(next?.groupCollection);
  const groupsSame = JSON.stringify(prev?.groups) === JSON.stringify(next?.groups);
  const symbolToChartSame = prev?.symbolToChart === next?.symbolToChart;
  const groupToChartSame = prev?.groupToChart === next?.groupToChart;
  const userDataSame = JSON.stringify(prev?.userData) === JSON.stringify(next?.userData);
  const scannersSame = JSON.stringify(prev?.scanners) === JSON.stringify(next?.scanners);
  const timeFramesSame = JSON.stringify(prev?.timeFrames) === JSON.stringify(next?.timeFrames);
  const scannerTitleSame = prev?.scannerTitle === next?.scannerTitle;
  const supportedResolutionsSame =
    JSON.stringify(prev?.supportedResolutions) === JSON.stringify(next?.supportedResolutions);
  const hasColumnsToChartSame = prev?.hasColumnsToChart === next?.hasColumnsToChart;
  const columnsToChartSame = JSON.stringify(prev?.columnsToChart) === JSON.stringify(next?.columnsToChart);
  const scannerLayoutSame = JSON.stringify(prev?.scannerLayout) === JSON.stringify(next?.scannerLayout);
  const lockedColumnsSame = JSON.stringify(prev?.lockedColumns) === JSON.stringify(next?.lockedColumns);
  const allowCustomInputWatchlistSame =
    JSON.stringify(prev?.allowCustomInputWatchlist) === JSON.stringify(next?.allowCustomInputWatchlist);

  return (
    groupCollectionSame &&
    groupsSame &&
    symbolToChartSame &&
    groupToChartSame &&
    userDataSame &&
    scannersSame &&
    timeFramesSame &&
    scannerTitleSame &&
    supportedResolutionsSame &&
    hasColumnsToChartSame &&
    columnsToChartSame &&
    scannerLayoutSame &&
    lockedColumnsSame &&
    allowCustomInputWatchlistSame
  );
});

const MWView = ({
  groupToChart,
  optionToView,
  symbolToChart,
  showOptionsModal,
  showLockedModal,
  showUpcomingModal,
  groupCollection,
  groups,
}) => {
  const [state] = useOutletContext();
  const {scannersData, loadingGroups} = state;
  const {scannerLayout, closeOptionsModal, closeLockedModal, closeUpcomingModal} = useContext(AppContext);
  if (!groupCollection || !Object.keys(groupCollection || {}).length || !groups?.length || loadingGroups) return null;
  const params = useParams();
  const [scannerTitle, setScannerTitle] = useState('');
  const [tabs, setTabs] = useState([]);
  const [scannerData, setScannerData] = useState(scannersData?.find((s) => s.slug === params?.id));

  useEffect(() => {
    if (scannersData?.length) {
      const scannerId = params?.id;
      const scData = scannersData?.find((s) => s.slug === scannerId);
      const layout = scData?.layoutGroups ?? {};
      if (layout?.length && !tabs?.length && !isEqual(layout, tabs)) {
        
        setTabs(layout);
      }
      if (scData?.title !== scannerTitle) {
        setScannerTitle(scData?.title ?? '');
      }
    }
  }, [params, scannersData]);

  useEffect(() => {
    const newScData = scannersData?.find((s) => s.slug === params?.id);
    if (!isEqual(newScData, scannerData)) {
      setScannerData(newScData);
    }
  }, [scannersData, params]);

  if (!tabs?.[0]?.scanners?.length) {
    return null;
  }
  return (
    <div className="mw-scanner-wrap">
      <ScannerHeader name={scannerTitle} />
      {Object.keys(scannerData ?? {}).length ? (
        <MWViewContentMemo
          scanners={tabs?.[0]?.scanners ?? []}
          scannerLayout={scannerLayout}
          optionsColumns={scannerData?.optionsColumns ?? {}}
          optionsAllowed={scannerData?.optionsAllowed ?? false}
          lockedColumns={scannerData?.lockedColumns ?? {}}
          hasColumnsToChart={scannerData?.hasColumnsToChart ?? false}
          columnsToChart={scannerData?.columnsToChart ?? []}
          supportedResolutions={scannerData?.chartConfig?.supportedResolutions ?? []}
          timeFrames={scannerData?.chartConfig?.time_frames ?? []}
          scannerTitle={scannerData?.title ?? null}
          scannerSlug={scannerData?.slug ?? null}
          displayChartDisabledMessage={scannerData?.displayChartDisabledMessage ?? true}
          allowCustomInputWatchlist={scannersData?.find((s) => s.slug === params?.id)?.allowCustomInputWatchlist}
          symbolToChart={symbolToChart}
          groupToChart={groupToChart}
          groups={groups}
          groupCollection={groupCollection}
        />
      ) : null}

      {/* Locked Modal */}
      <Modal isOpen={showLockedModal} onRequestClose={closeLockedModal} style={customStylesLocked}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeLockedModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>
            To unlock this feature, reach out in the mod chat or{' '}
            <a
              href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
              target="_blank"
              rel="noreferrer"
            >
              click here to schedule a call with our VIP Service Squad
            </a>
            .
          </h1>
          {/* <a
            className="click-here"
            href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Click Here to Upgrade
          </a> */}
        </div>
      </Modal>
      {/* Upcoming Modal */}
      <Modal isOpen={showUpcomingModal} onRequestClose={closeUpcomingModal} style={customStylesUpcoming}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeUpcomingModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>Feature Coming Soon</h1>
        </div>
      </Modal>
      {/* Options Modal */}
      <Modal isOpen={showOptionsModal} onRequestClose={closeOptionsModal} style={customStylesOptions}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeOptionsModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <OptionView
            optionToView={optionToView}
            groupCollection={groupCollection}
            optionsLayout={scannersData?.find((s) => s.slug === params?.id)?.optionsLayout ?? {}}
            showSecondaryHeader={false}
          />
        </div>
      </Modal>
    </div>
  );
};

const optionValuesSame = (scannersData, prev, next) => {
  const id = window.location.pathname.replace('/scanners/', '');
  const optionsLayout = scannersData?.find((s) => s.slug === id)?.optionsLayout ?? {};
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (JSON.stringify(prevSymbolData) !== JSON.stringify(nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every(
        (k) => JSON.stringify(prevSymbolData?.[k]?.value) === JSON.stringify(nextSymbolData?.[k]?.value),
      );
    });
    return areSame;
  }
  return true;
};

export default React.memo(MWView, (prev, next) => {
  if (prev?.showLockedModal && next?.showLockedModal) {
    return prev?.showLockedModal === next?.showLockedModal;
  }
  if (prev?.showUpcomingModal && next?.showUpcomingModal) {
    return prev?.showUpcomingModal === next?.showUpcomingModal;
  }

  if (prev?.showOptionsModal && next?.showOptionsModal && prev?.optionToView && next?.optionToView) {
    return prev.optionToView === next.optionToView && optionValuesSame(next?.scannersData, prev, next);
  }

  const collectionSame = JSON.stringify(prev?.groupCollection) === JSON.stringify(next?.groupCollection);
  const groupsSame = JSON.stringify(prev?.groups) === JSON.stringify(next?.groups);
  const showOptionsModalSame = prev.showOptionsModal === next.showOptionsModal;
  const showLockedModalSame = prev?.showLockedModal === next?.showLockedModal;
  const showUpcomingModalSame = prev?.showUpcomingModal === next?.showUpcomingModal;
  const symbolToChartSame = prev?.symbolToChart === next?.symbolToChart;
  const groupToChartSame = prev?.groupToChart === next?.groupToChart;
  return (
    collectionSame &&
    groupsSame &&
    showOptionsModalSame &&
    showLockedModalSame &&
    showUpcomingModalSame &&
    symbolToChartSame &&
    groupToChartSame
  );
});
// export default MWView;
