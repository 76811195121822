/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
export const customIndicators = (pjs) => [];

export const squeezeProIndicator = (pjs) => ({
  name: 'Squeeze PRO Indicator',
  metainfo: {
    _metainfoVersion: 51,
    id: 'SqueezePRO@tv-basicstudies-1',
    description: 'Squeeze PRO Indicator',
    shortDescription: 'Squeeze PRO',
    is_price_study: false,
    isCustomIndicator: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
        palette: 'paletteId1',
      },
      {
        id: 'plot_1',
        type: 'line',
        palette: 'paletteId2',
      },
      {
        id: 'plot_3',
        type: 'colorer',
        target: 'plot_0',
        palette: 'paletteId1',
      },
      {
        id: 'plot_4',
        type: 'colorer',
        target: 'plot_1',
        palette: 'paletteId2',
      },
    ],
    palettes: {
      paletteId1: {
        colors: {
          0: {
            name: 'First color',
          },
          1: {
            name: 'Second color',
          },
        },
      },
      paletteId2: {
        colors: {
          0: {
            name: 'First color',
          },
          1: {
            name: 'Second color',
          },
          2: {
            name: 'Third color',
          },
          3: {
            name: 'Fourth color',
          },
          4: {
            name: 'Fifth color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: 'blue',
              width: 5,
              style: 0,
            },
            1: {
              color: 'aqua',
              width: 3,
              style: 1,
            },
          },
        },
        paletteId2: {
          colors: {
            0: {
              color: 'blue',
              width: 5,
              style: 0,
            },
            1: {
              color: 'yellow',
              width: 3,
              style: 1,
            },
            2: {
              color: 'red',
              width: 3,
              style: 1,
            },
            3: {
              color: 'orange',
              width: 3,
              style: 1,
            },
            4: {
              color: 'lime',
              width: 3,
              style: 1,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
        },
        plot_1: {
          linestyle: 0,
          linewidth: 3,
          plottype: 6,
          trackPrice: false,
          transparency: 0,
          visible: true,
        },
      },
      precision: 4,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: 'MOM',
        histogramBase: 0,
      },
      plot_1: {
        title: 'SQZ',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;
      const source = context.new_var(pjs.Std.close(context));

      const length = 20;
      const tr = context.new_var(pjs.Std.tr(false, context));
      const ma = pjs.Std.sma(source, length, context);
      const devBB = pjs.Std.stdev(source, length, this._context);
      const devKC = pjs.Std.sma(tr, length, this._context);

      // Bollinger 2x
      const upBB = ma + devBB * 2;
      const lowBB = ma - devBB * 2;

      // Keltner 2x
      const upKCWide = ma + devKC * 2;
      const lowKCWide = ma - devKC * 2;

      // Keltner 1.5x
      const upKCNormal = ma + devKC * 1.5;
      const lowKCNormal = ma - devKC * 1.5;

      // Keltner 1x
      const upKCNarrow = ma + devKC;
      const lowKCNarrow = ma - devKC;

      const sqzOnWide = lowBB >= lowKCWide && upBB <= upKCWide; // WIDE SQUEEZE: ORANGE
      const sqzOnNormal = lowBB >= lowKCNormal && upBB <= upKCNormal; // NORMAL SQUEEZE: RED
      const sqzOnNarrow = lowBB >= lowKCNarrow && upBB <= upKCNarrow; // NARROW SQUEEZE: YELLOW
      const sqzOffWide = lowBB < lowKCWide && upBB > upKCWide; // FIRED WIDE SQUEEZE: GREEN
      const noSqz = sqzOnWide === false && sqzOffWide === false; // NO SQUEEZE: BLUE

      const high = context.new_var(pjs.Std.high(this._context));
      const low = context.new_var(pjs.Std.low(this._context));
      const close = context.new_var(pjs.Std.close(this._context));
      const linregSource = context.new_var(
        source -

          pjs.Std.avg(
            pjs.Std.avg(pjs.Std.highest(high, length, this._context), pjs.Std.lowest(low, length, this._context)),
            pjs.Std.sma(close, length, this._context),
          ),
      );
      const mom = pjs.Std.linreg(linregSource, length, 0);

      const sqColor = noSqz ? 0 : sqzOnNarrow ? 1 : sqzOnNormal ? 2 : sqzOnWide ? 3 : 4;

      if (mom > 0) {
        if (mom > pjs.Std.nz(mom[1])) {
          return [mom, 0, 0, sqColor];
        }
      }

      return [mom, 0, 1, sqColor];
    };
  },
});

export const MovingAverages8EMA = (pjs) => ({
  name: 'Moving Averages 8 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages8@tv-basicstudies-1',
    description: 'Moving Averages 8 EMA',
    shortDescription: '8 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
        palette: 'paletteId1',
      },
    ],
    palettes: {
      paletteId1: {
        colors: {
          0: {
            name: 'First color',
          },
        },

      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#F17358',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#F17358',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 8 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const source = this._context.new_var(pjs.Std.close(this._context));

      const fast = pjs.Std.ema(source, 8, this._context);

      return [fast];
    };
  },
});

export const MovingAverages21EMA = (pjs) => ({
  name: 'Moving Averages 21 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages21@tv-basicstudies-1',
    description: 'Moving Averages 21 EMA',
    shortDescription: '21 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    palettes: {
      paletteId1: {
        valToIndex: {
          0: 0,
        },
        colors: {
          0: {
            name: 'First color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#18B4CB',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#18B4CB',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 21 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const src = context.new_var(pjs.Std.close(context));
      const slow21 = pjs.Std.ema(src, 21, this._context);
      return [slow21];
    };
  },
});

export const MovingAverages55EMA = (pjs) => ({
  name: 'Moving Averages 55 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages55@tv-basicstudies-1',
    description: 'Moving Averages 55 EMA',
    shortDescription: '55 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    palettes: {
      paletteId1: {
        colors: {
          0: {
            name: 'First color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#E5C25B',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#E5C25B',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 55 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const src = context.new_var(pjs.Std.close(this._context));
      const slow55 = pjs.Std.ema(src, 55, this._context);
      return [slow55];
    };
  },
});
