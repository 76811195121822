import axios from 'axios';

const formatWatchlist = (watchlistRes) => {
  if (!watchlistRes?.length) {
    return {};
  }
  const formattedWatchlist = watchlistRes.reduce((acc, item) => {
    const formattedSymbols = item?.symbols?.length
      ? item?.symbols?.reduce((symbolAcc, symbol) => {
          if (symbol?.group && symbol?.symbol) {
            if (!symbolAcc[symbol.group]) {
              return {...symbolAcc, [symbol.group]: [symbol.symbol]};
            }
            return {...symbolAcc, [symbol.group]: [...symbolAcc[symbol.group], symbol.symbol]};
          }
          if (symbol?.symbol) {
            return {...symbolAcc, NoParent: [...(symbolAcc?.NoParent ?? []), symbol.symbol]};
          }
          return symbolAcc;
        }, {})
      : {};
    if (item?.slug) {
      acc[item.slug] = {...item, formattedSymbols};
    }
    return acc;
  }, {});
  return formattedWatchlist;
};

export const getWatchlists = async ({token}) => {
  try {
    const watchlists = await axios.get('/watchlists', {
      params: {
        token,
      },
    });
    if (!watchlists?.data?.data?.length) {
      return {};
    }

    return formatWatchlist(watchlists.data.data);
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const addSymbolToWatchlist = async ({token, symbol, group = null}) => {
  try {
    const data = {
      token,
      symbol,
    };
    if (group) {
      data.group = group;
    }
    const addWatchlist = await axios.post('/watchlists/add-symbol', data);
    if (!addWatchlist?.data?.data?.length) {
      return {};
    }
    return formatWatchlist(addWatchlist.data.data);
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const removeSymbolFromWatchlist = async ({token, symbol}) => {
  try {
    const data = {
      token,
      symbol,
    };
    const removeWatchlist = await axios.post('/watchlists/remove-symbol', data);
    if (!removeWatchlist?.data?.data?.length) {
      return {};
    }
    return formatWatchlist(removeWatchlist.data.data);
  } catch (e) {
    console.error(e);
    return {};
  }
};
