import React from 'react';
import {useOutletContext} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
// import Toggle from '../Toggles/Toggle';
// import mmlLogo from '../../Icons/mml-logo.png';
import ScannerLogo from '../../Icons/scanner_image.png';

const moment = require('moment');

const ScannerHeader = ({name}) => {
  const [state] = useOutletContext();
  const {userData, showChart } = state;
  const freeAccess = userData?.free_access;
  const headerTitle = name ? (
    <>
      <img className="w-8 h-8" src={ScannerLogo} alt="Logo" />
      {/* <DarkPoolIcon /> */}
      <h1 className="text-header-color font-bold text-[24px] leading-[28px]">{name}</h1>
    </>
  ) : null;

  return (
    <div className={`${showChart ? 'chart-visible' : ''} mx-auto group-header`}>
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          {/* {headerTitle} */}
        </div>
        {/* <div className="flex gap-7 pr-5 dark-mode-toggle">
          <div className="flex items-center">
            <Toggle theme={theme} setTheme={setTheme} />
          </div>
        </div> */}
      </div>
      {/* <div className="mt-2">
        <small>To change scanner alert subscriptions, pleace go to <u><a href="/" target="_blank" rel="noreferrer"> Alert Settings</a></u>.</small>
      </div> */}
    </div>
  );
};

export default React.memo(ScannerHeader, (prev, next) => {
  const nameSame = prev.name === next.name;
  return nameSame;
});

// export default ScannerHeader;
