const light = {
  mainBackground: '#fff',
  mainSidebarBackground: '#fff',
  menuItemBackground: '#fff',
  menuItemBorderActive: '#41E241',
  menuItemBackgroundActive: '#41E241',
  menuItemTextPrimary: '#9AA2AC',
  menuItemTextActive: '#fff',
  headerText: '#464b45',
};
export default light;
