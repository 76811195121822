/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef, useState, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faLock, faHeart} from '@fortawesome/free-solid-svg-icons';
import {faHeart as faHeartRegular} from '@fortawesome/free-regular-svg-icons';
import { isEqual } from 'lodash';
import PSUIcon from '../../Icons/PSU-icon.png';
import ScannerContext from '../../Context/ScannerContext';
import AppContext from '../../Context/AppContext';

const NumAbbr = require('number-abbreviate');

const getBackgroundColor = (rowValue, dataType) => {
  const {displayOptions, key} = dataType;

  if (!displayOptions || !Object.keys(displayOptions ?? {}).length) return {};
  const {formula} = displayOptions;
  let rowStyle = {};
  const rowVal = rowValue[key]?.value ?? rowValue[key];
  if (formula?.length) {
    formula.forEach((f) => {
      const {operations, style} = f;
      operations.forEach((o) => {
        const {operator, value} = o;
        if (operator === '=' && rowVal === value) {
          rowStyle = {...(rowStyle ?? {}), ...(style ?? {})};
        }
      });
    });
  }
  return rowStyle;
};

const isAddedToWatchlist = ({activeWatchlist, allowedWatchlist, groupName, value}) => {
  if (!allowedWatchlist || !activeWatchlist) return false;
  if (!Object.keys(activeWatchlist ?? {})?.length) return false;
  const foundGroup = activeWatchlist[groupName];
  if (!foundGroup) return false;
  return foundGroup?.includes(value);
};

const displayRowValue = ({
  rowValue,
  dataType,
  dataTypes,
  overrideKey = null,
  openOptions = null,
  optionsColumns = [],
  optionsAllowed = true,
  openLockedModal = null,
  lockedColumns = [],
  allowedChart = true,
  allowedWatchlist = false,
  groupName = null,
  handleAddToWatchlist,
  handleClickSymbol,
  addedToWatchlist,
}) => {
  const {display, key, iconType, type} = dataType;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (
    (!rowValue ||
      (!rowValue[key]?.value && !rowValue[key]) ||
      (overrideKey && !rowValue[overrideKey]?.value && !rowValue[overrideKey])) &&
    type !== 'pop-out'
  )
    return '';

  const {value, style} = type !== 'pop-out' ? rowValue[key] ?? rowValue[overrideKey] : {value: null, style: null};
  const curVal = value ?? rowValue[key] ?? rowValue[overrideKey];

  if (!curVal && display !== 'joined-column' && type !== 'pop-out' && display !== 'ps-image') return '';

  // const formatPercentage = new Intl.NumberFormat('en-US', {
  //     style: 'percent',
  // });

  const formatNumber = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });


 
  const baseClass =
    Object.keys(style ?? {})?.length && Object.keys(style ?? {}).includes('backgroundColor')
      ? 'color-item '
      : 'no-color-item ';

  if (lockedColumns?.length && lockedColumns?.includes(key)) {
    return (
      <button
        type="button"
        label="open options"
        onClick={() => {
          if (!openLockedModal) return;
          openLockedModal();
        }}
        className="open-options"
      >
        <FontAwesomeIcon icon={faLock} />
      </button>
    );
  }
  switch (display) {
    case 'percentage':
      if (curVal === 'Gift Gap') {
        return (
          <span className={baseClass} style={style ?? {}}>
            {curVal}
          </span>
        );
      }
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${curVal}%`}
        </span>
      ) : null;
    case 'icon':
      return curVal ? (
        <span className="text-sky-400" style={style ?? {}}>
          <i className={style?.overrideValue ? style?.overrideValue : iconType} />
        </span>
      ) : null;
    case 'custom':
      return curVal ? ( // eslint-disable-next-line
        <span className={baseClass} style={style ?? {}} dangerouslySetInnerHTML={{__html: curVal}} />
      ) : null;
    case 'money-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`$${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'number-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {`${NumAbbr(Number(curVal), 2)}`.toUpperCase()}
        </span>
      ) : null;
    case 'money':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatPrice.format(Number(curVal))}
        </span>
      ) : null;
    case 'number':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : null;
    case 'tags':
      return curVal?.length && Array.isArray(curVal) ? (
        <div className="grid auto-cols-max gap-y-2 gap-x-1 justify-center">
          {curVal.map((tag) => (
            <div className="flex items-center justify-center" key={tag}>
              <span
                className={`${
                  Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
                } relative inline mx-auto items-center justify-center rounded-full border border-gray-300 px-2 py-0.5 hover:bg-slate-300`}
              >
                <div
                  className={`${
                    Object.keys(style ?? {}).length ? '' : 'no-style'
                  } text-sm font-medium text-gray-900 tag-name`}
                  style={style ?? {}}
                >
                  {tag}
                </div>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span
            className={`${curVal?.length ? 'border border-gray-300 ' : ''} ${
              Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
            } relative inline mx-auto items-center justify-center rounded-full px-2 py-0.5 hover:bg-slate-300`}
          >
            <div
              className={`${
                Object.keys(style ?? {}).length ? '' : 'no-style'
              } text-sm font-medium text-gray-900 tag-name`}
              style={style ?? {}}
            >
              {curVal}
            </div>
          </span>
        </div>
      );
    case 'bullish-bearish':
      return curVal ? (
        <span
          className={baseClass}
          style={{
            ...(getBackgroundColor(rowValue, dataType) ?? {}),
            ...(style ?? {}),
          }}
        >
          {curVal}
        </span>
      ) : null;
    case 'joined-column': {
      const {columnA, columnB, columnC} = rowValue[key] ?? {columnA: null, columnB: null, columnC: null};
      const columnADataType = dataTypes.find((dt) => dt.key === dataType.columnA);
      const columnBDataType = dataTypes.find((dt) => dt.key === dataType.columnB);

      if (columnA && columnB) {
        if (!columnC) {
          return (
            <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnADataType,
                  dataTypes,
                  overrideKey: dataType.columnA,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  lockedColumns,
                  allowedChart,
                  allowedWatchlist,
                  groupName,
                  handleAddToWatchlist,
                  handleClickSymbol,
                  addedToWatchlist
                })}
              </span>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnBDataType,
                  dataTypes,
                  overrideKey: dataType.columnB,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  lockedColumns,
                  allowedChart,
                  allowedWatchlist,
                  groupName,
                  handleAddToWatchlist,
                  handleClickSymbol,
                  addedToWatchlist
                })}
              </span>
            </span>
          );
        }
        const columnCDataType = dataTypes.find((dt) => dt.key === dataType.columnC);
        if (columnB?.value === 'A+ Setup') {
          return (
            <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnADataType,
                  dataTypes,
                  overrideKey: dataType.columnA,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  lockedColumns,
                  allowedChart,
                  allowedWatchlist,
                  groupName,
                  handleAddToWatchlist,
                  handleClickSymbol,
                  addedToWatchlist
                })}
              </span>
              <span>
                {displayRowValue({
                  rowValue,
                  dataType: columnBDataType,
                  dataTypes,
                  overrideKey: dataType.columnB,
                  openOptions,
                  optionsColumns,
                  optionsAllowed,
                  openLockedModal,
                  lockedColumns,
                  allowedChart,
                  allowedWatchlist,
                  groupName,
                  handleAddToWatchlist,
                  handleClickSymbol,
                  addedToWatchlist
                })}
              </span>
            </span>
          );
        }
        return (
          <span style={{display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center'}}>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnADataType,
                dataTypes,
                overrideKey: dataType.columnA,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                lockedColumns,
                allowedChart,
                allowedWatchlist,
                groupName,
                handleAddToWatchlist,
                handleClickSymbol,
                addedToWatchlist
              })}
            </span>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnBDataType,
                dataTypes,
                overrideKey: dataType.columnB,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                lockedColumns,
                allowedChart,
                allowedWatchlist,
                groupName,
                handleAddToWatchlist,
                handleClickSymbol,
                addedToWatchlist
              })}
            </span>
            <span>
              {displayRowValue({
                rowValue,
                dataType: columnCDataType,
                dataTypes,
                overrideKey: dataType.columnC,
                openOptions,
                optionsColumns,
                optionsAllowed,
                openLockedModal,
                lockedColumns,
                allowedChart,
                allowedWatchlist,
                groupName,
                handleAddToWatchlist,
                handleClickSymbol,
                addedToWatchlist
              })}
            </span>
          </span>
        );
      }
      return null;
    }
    case 'pop-out': {
      const displayOptionsPopout = Object.keys(rowValue ?? {}).some((r) => optionsColumns?.includes(r)) && openOptions;
      return displayOptionsPopout ? (
        !optionsAllowed ? (
          <button
            type="button"
            label="open options"
            onClick={() => {
              if (!openLockedModal) return;
              openLockedModal();
            }}
            className="open-options"
          >
            {' '}
            <FontAwesomeIcon icon={faLock} />
          </button>
        ) : (
          <button
            type="button"
            label="open options"
            onClick={() => {
              openOptions(rowValue);
            }}
            className="open-options"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </button>
        )
      ) : null;
    }
    case 'ps-image': {
      if (curVal) {
        return (
          <span className="ps-icon-wrap">
            <img src={PSUIcon} className="ps-icon" alt="ps-icon" />
          </span>
        );
      }
      return null;
    }
    default:
      return type === 'number' && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {formatNumber.format(Number(curVal))}
        </span>
      ) : key === 'Symbol' ? (
        allowedWatchlist ? (
          <div className="flex items-center gap-2 justify-center symbol-watchist-wrap" style={{zIndex: 99999999}}>
            <button
              onClick={() => {
                if (!allowedChart) return;
                handleClickSymbol(key, curVal);
              }}
              type="button"
            >
              <span
                style={style ?? {}}
                className={`${baseClass} symbol-item ${
                  allowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
                } `}
              >
                {curVal}
              </span>
            </button>
            <button
              onClick={() => {
                if (!allowedWatchlist) return;
                handleAddToWatchlist(groupName, curVal, !addedToWatchlist);
              }}
              type="button"
            >
              <FontAwesomeIcon
                className={`${addedToWatchlist ? 'added' : ''} watchlist-item`}
                icon={addedToWatchlist ? faHeart : faHeartRegular}
              />
            </button>
          </div>
        ) : (
          <button
            type="button"
            style={style ?? {}}
            onClick={() => {
              if (!allowedChart) return;
              handleClickSymbol(key, curVal);
            }}
            className={`${baseClass} symbol-item ${
              allowedChart ? 'cursor-pointer' : ' cursor-default symbol-item-no-link'
            } `}
          >
            {/* {curVal?.charAt(0) === 'e' ? curVal.substring(1) : curVal} */}
            {curVal}
          </button>
        )
      ) : (
        <span style={style ?? {}} className={`${baseClass + (key === 'Symbol' ? 'symbol-item' : '')}`}>
          {typeof curVal === 'string' && curVal?.includes('A+ Setup') ? 'A+' : curVal}
        </span>
      );
  }
};

const ScannerCell = ({
  rowValue,
  dataType,
  dataTypes,
  optionsColumns,
  row,
  index,
  optionsAllowed,
  lockedColumns,
  allowedChart,
  allowedWatchlist,
  groupName,
  group,
  addedToWatchlist,
}) => {
  const {getSymbolData, openOptionsModal, openLockedModal, handleAddToWatchlist} = useContext(AppContext);
  const openOptions = (r) => {
    if (!openOptionsModal) return;
    openOptionsModal(r, groupName);
  };
  const handleClickSymbol = (key = null, symbol = null) => {
    if (symbol && key === 'Symbol' && group?.allowedChart) {
      // if (displayChartDisabledMessage) {
      //   // eslint-disable-next-line no-alert
      //   alert(
      //     'Charts are offline to help increase SAM speed. We will have these up and running soon. Thanks for your patience!',
      //   );
      //   return;
      // }
      getSymbolData(symbol, groupName);
    }
  };
  return (
    <TableCell
      key={`${row ? row._id?.value : ''}-${dataType.key}-${row?.Symbol?.value}-${dataType.type}-${index * 2}`}
      align="center"
      className={`${dataType.convertTo === 'time' ? 'whitespace-nowrap' : 'whitespace-nowrap'} ${dataType.key} ${
        row?.Symbol?.value
      }`}
    >
      <span className="scanner-item">
        {displayRowValue({
          rowValue,
          dataType,
          dataTypes,
          overrideKey: null,
          openOptions,
          optionsColumns,
          optionsAllowed,
          openLockedModal,
          lockedColumns,
          allowedChart,
          allowedWatchlist,
          groupName,
          handleAddToWatchlist,
          handleClickSymbol,
          addedToWatchlist,
        })}
      </span>
    </TableCell>
  )
};

const MemoizedScannerCell = React.memo(ScannerCell, (prev, next) => {
  const prevNextSame = isEqual(prev?.row, next?.row);
  const rowValueSame = isEqual(prev?.rowValue, next?.rowValue);
  const dataTypeSame = isEqual(prev?.dataType, next?.dataType);
  const dataTypesSame = isEqual(prev?.dataTypes, next?.dataTypes);
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const optionsAllowedSame = prev?.optionsAllowed === next?.optionsAllowed;
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const allowedChartSame = prev?.allowedChart === next?.allowedChart;
  const allowedWatchlistSame = prev?.allowedWatchlist === next?.allowedWatchlist;
  const groupNameSame = prev?.groupName === next?.groupName;
  const activeWatchlistSame = isEqual(prev?.activeWatchlist, next?.activeWatchlist);
  const addedToWatchlistSame = prev?.addedToWatchlist === next?.addedToWatchlist;
  return (
    prevNextSame &&
    rowValueSame &&
    dataTypeSame &&
    dataTypesSame &&
    optionsColumnsSame &&
    optionsAllowedSame &&
    lockedColumnsSame &&
    allowedChartSame &&
    allowedWatchlistSame &&
    groupNameSame &&
    activeWatchlistSame && 
    addedToWatchlistSame
  );
});

const ScannerItem = ({row, activeWatchlist, groupName}) => {
  const {group, optionsColumns, optionsAllowed, lockedColumns} =
    useContext(ScannerContext);
  const cellRef = useRef(null);
  const [highlightKeys, setHighlightKeys] = useState(Object.keys(row).filter((k) => k.includes('Prev')));
  const [filteredDataTypes, setFilteredDataTypes] = useState([]);
  const [addedToWatchlist, setIsAddedToWatchlist] = useState(false);

  useEffect(() => {
    if (!highlightKeys?.length) {
      const prevHighlightKeys = Object.keys(row).filter((k) => k.includes('Prev'));
      if (!isEqual(prevHighlightKeys, highlightKeys)) {
        setHighlightKeys(prevHighlightKeys);
      }
    }
  }, [row]);

  const highlightRow = (className, prevRowKey, newRowKey) => {
    if (!row[prevRowKey] || !row[newRowKey]) return;
    const prevValue = Number(row[prevRowKey]?.value);
    const newRowVal = Number(row[newRowKey]?.value);
    const animationClassNameIncrease = `${className}Increase`;
    const animationClassNameDecrease = `${className}Decrease`;
    const animationAnimationOutClassName = `${className}AnimationOut`;
    cellRef.current.classList.remove(animationAnimationOutClassName);
    if (prevValue < newRowVal) {
      cellRef.current.classList.add(animationClassNameIncrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
        // dispatch({
        //   type: 'GROUP_UPDATED',
        //   payload: {keyId: row?.keyId, group: group.group},
        // });
      }, 1000);
    } else if (prevValue > newRowVal) {
      cellRef.current.classList.add(animationClassNameDecrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
        // dispatch({
        //   type: 'GROUP_UPDATED',
        //   payload: {keyId: row?.keyId, group: group.group},
        // });
      }, 1000);
    }
  };

  useEffect(() => {
    if (cellRef.current && row.update) {
      if (!highlightKeys?.length) {
        return;
      }
      const rowKeys = highlightKeys.map((k) => {
        const newKey = k.replace('Prev', '');
        return {
          key: newKey,
          prevKey: k,
        };
      });
      rowKeys.forEach((k) => {
        if (row[k.key] && row[k.prevKey] && row[k.key]?.value !== row[k.prevKey]?.value) {
          highlightRow(k.key, k.prevKey, k.key);
        }
      });
    }
  }, [row]);


  useEffect(() => {
    if (!group?.dataTypes?.length) return;
    setFilteredDataTypes(group?.dataTypes.filter((dt) => !dt?.hideColumn));
  }, [group?.dataTypes])

  useEffect(() => {
    const isAdded = isAddedToWatchlist({activeWatchlist, allowedWatchlist: group?.allowedWatchlist, groupName, value: row?.Symbol?.value});
    if (addedToWatchlist !== isAdded) {
      setIsAddedToWatchlist(isAdded);
    }
  }, [activeWatchlist, row]);

  return (
    <TableRow hover tabIndex={-1} ref={cellRef}>
      {filteredDataTypes?.length ? filteredDataTypes.map((dataType, index) => (
          <MemoizedScannerCell
            key={dataType.key}
            rowValue={row}
            dataType={dataType}
            dataTypes={group?.dataTypes}
            optionsColumns={optionsColumns}
            row={row}
            index={index}
            optionsAllowed={optionsAllowed}
            lockedColumns={lockedColumns}
            allowedChart={group?.allowedChart}
            allowedWatchlist={group?.allowedWatchlist}
            groupName={groupName}
            group={group}
            addedToWatchlist={dataType?.key === "Symbol" ? addedToWatchlist : false}
          />
        )) : null}
    </TableRow>
  );
};

export default React.memo(ScannerItem, (prev, next) => {
  const rowValueSame = isEqual(prev?.row, next?.row)
  const watchlistSame = isEqual(prev?.activeWatchlist, next?.activeWatchlist);
  if (!watchlistSame) {
    const isAddedPrev = isAddedToWatchlist({activeWatchlist: prev?.activeWatchlist, allowedWatchlist: true, groupName: prev?.groupName, value: prev?.row?.Symbol?.value});
    const isAddedNext = isAddedToWatchlist({activeWatchlist: next?.activeWatchlist, allowedWatchlist: true, groupName: next?.groupName, value: next?.row?.Symbol?.value});
    return rowValueSame && isAddedPrev === isAddedNext;
  }
  return rowValueSame && watchlistSame;
});
