import React, {useEffect, useMemo} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import LoadingUser from '../Components/LoadingUser/LoadingUser';
// import Sidebar from '../Components/Sidebar/Sidebar';


const Dashboard = ({state, dispatch,  wsSocket}) => {
  const {initialized, isConnected, didConnect, groups, groupCollection, loadingGroups} = state;

  useEffect(() => {
    if (!loadingGroups) {
      return;
    }
    const joinedGroups = [...(groups ?? [])].filter(
      (gr) => gr.joined && Object.keys(groupCollection || {})?.includes(gr.group),
    );
    if (
      groups?.length === Object.keys(groupCollection || {}).length &&
      groups?.length &&
      Object.keys(groupCollection || {}).length &&
      joinedGroups.length &&
      loadingGroups
    ) {
      dispatch({type: 'SET_CONNECTED_STATUS'});
    }
  }, [groupCollection, groups]);

  const contextValue = useMemo(() => [state, dispatch, wsSocket], [state, dispatch, wsSocket]);

  return initialized ? (
   isConnected ? (
      <div className="flex w-full">
        {/* <Sidebar scannersData={scannersData} theme={theme} setTheme={setTheme} /> */}
        <div className="flex-grow w-5/6 pt-2.5 px-4 relative main-container">
          <Outlet
            context={contextValue}
          />
        </div>
      </div>
    ) : didConnect ? (
      <LoadingUser message="Reconnecting..." />
    ) : (
      <Navigate replace to="/access-denied" />
    )
  ) : (
    <LoadingUser message="Loading Scanners..." />
  );
};

// Dashboard.whyDidYouRender = true;
export default Dashboard;
