/* eslint-disable import/no-extraneous-dependencies */

import React, {useState, useEffect, memo} from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import {visuallyHidden} from '@mui/utils';
import { isEqual } from 'lodash';

const ScannerTableHeader = memo(
  ({order, orderBy, onRequestSort, tableHeaders, multiScreen}) => {
    const [columns, setColumns] = useState(tableHeaders);

    useEffect(() => {
      if (!tableHeaders?.length) return;
      const cols = tableHeaders
        .filter((column) => !column?.hideColumn)
        .map((col) => {
          const title = col.title
            .replace('Percentage', '%')
            .replace('Percent', '%')
            .replace('Average', 'Avg.')
            .replace('Volume', 'Vol.')
            .replace('Count', 'Cnt.');
          return {
            ...col,
            title: col.title === 'AI' ? '' : title,
          };
        });
        if (!isEqual(cols, columns)) {
          setColumns(cols);
        }
    }, [tableHeaders]);

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns?.length
            ? columns.map((header, index) => (
                <TableCell
                  key={`${header.key}-${index * 2}`}
                  align="center"
                  padding="normal"
                  sortDirection={orderBy === header.title ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header.key}
                    direction={orderBy === header.key ? order : 'asc'}
                    onClick={createSortHandler(header.key)}
                    className={`${
                      orderBy !== header.key && multiScreen ? 'not-active whitespace-nowrap' : 'whitespace-nowrap'
                    } table-header-light`}
                  >
                    {header.title}
                    {orderBy === header.key ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))
            : null}
        </TableRow>
      </TableHead>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

ScannerTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default ScannerTableHeader;
