import React, {Fragment, useEffect, useContext, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import Search from './Fields/Search';
import Range from './Fields/Range';
import MultiSelect from './Fields/MultiSelect';
import MultiSelectWithSearch from './Fields/MultiSelectWithSearch';
import ScannerContext from '../../Context/ScannerContext';

const AllFilters = () => {
  const {group, multiScreen, handleUpdateMultiSelect} = useContext(ScannerContext);
  if (!group?.searchValue?.filters.length) return null;
  const [groupFilters, setGroupFilters] = useState([]);
  const [,dispatch] = useOutletContext();

  useEffect(() => {
    if (!group?.searchValue?.filters.length) return;
    setGroupFilters(group?.searchValue?.filters?.filter((filt) => filt?.filterable?.type !== 'search'));
  }, [group?.searchValue?.filters]);

  return groupFilters.map((filter) => (
      <Fragment key={filter?.key ?? filter?.type}>
        {/* {filter &&
      filter?.filterable?.type === 'search' &&
      filter?.type === 'string' ? (
        <Search
          filter={filter}
          group={group}
          dispatch={dispatch}
          multiScreen={multiScreen}
        />
      ) : null} */}
        {filter && filter?.filterable?.type === 'range' && !multiScreen ? (
          <Range filter={filter} group={group} dispatch={dispatch} />
        ) : null}
        {filter && filter?.type === 'array' ? (
          <MultiSelect
            filter={filter}
            group={group}
            handleUpdateMultiSelect={handleUpdateMultiSelect}
            dispatch={dispatch}
            label={filter?.title}
            multiScreen={multiScreen}
          />
        ) : null}
        {filter && filter?.type === 'string' && filter?.filterable?.type === 'multiselect' ? (
          <MultiSelectWithSearch
            filter={filter}
            group={group}
            handleUpdateMultiSelect={handleUpdateMultiSelect}
            dispatch={dispatch}
            label={filter?.title}
            multiScreen={multiScreen}
          />
        ) : null}
      </Fragment>
    ));
};

export default AllFilters;
