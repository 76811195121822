import React, {useState, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import ScannerContext from '../../../Context/ScannerContext';

const Range = ({filter}) => {
  const [minValue, setMinValue] = useState(filter?.value?.min || '');
  const [maxValue, setMaxValue] = useState(filter?.value?.max || '');
  const {group, multiScreen,} = useContext(ScannerContext);
  const [, dispatch] = useOutletContext();

  return (
    <div className="flex flex-col grow gap-2 w-1/6">
      <label
        htmlFor="symbol"
        className={`${
          multiScreen ? 'text-xs font-bold' : 'text-xs'
        } block font-small text-header-color`}
      >
        {filter?.title}
      </label>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2 w-full">
          {!multiScreen ? (
            ''
            // <label
            //   htmlFor="name"
            //   className="block text-sm font-medium text-[#70769B] range-label"
            // >
            //   Min
            // </label>
          ) : null}
          <div
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-transparent focus-within:border-gray-300 "
          >
            <input
              min={filter?.filterable?.floor ?? 0}
              type={filter?.type ?? 'number'}
              name={filter?.key}
              id={filter?.key}
              className={`${
                multiScreen ? 'text-sm' : 'text-md'
              } block w-full border-0 py-0 px-1 bg-transparent relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] focus:ring-0`}
              placeholder={
                filter?.filterable?.placeholder?.low ??
                `Min ${
                  filter?.filterable?.floor
                    ? `(${filter?.filterable?.floor} minimum)`
                    : ''
                }`
              }
              onChange={(e) => {
                const value = Number(e.target.value);
                const minValueNum = Number(filter?.filterable?.floor);

                dispatch({
                  type: 'UPDATE_FILTER_VALUES',
                  payload: {
                    updatedValue:
                      value < minValueNum && !Number.isNaN(minValueNum)
                        ? minValueNum
                        : value ?? '',
                    key: filter?.key,
                    minMax: 'min',
                    group,
                  },
                });
                setMinValue(
                  value < minValueNum && !Number.isNaN(minValueNum)
                    ? minValueNum
                    : value ?? '',
                );
              }}
              value={minValue || ''}
            />
          </div>
          {multiScreen ? (
            <label
              htmlFor="name"
              className={`${
                multiScreen ? 'range-label-multi' : ''
              } block text-xs font-medium text-[#70769B]  range-label`}
            >
              Min
            </label>
          ) : null}
        </div>
        <div className="flex flex-col gap-2 w-full">
          {!multiScreen ? (
            ''
            // <label
            //   htmlFor="name"
            //   className="block text-sm font-medium text-[#70769B]  range-label"
            // >
            //   Max
            // </label>
          ) : null}
          <div
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-transparent focus-within:border-gray-300 "
          >
            <input
              type={filter?.type ?? 'number'}
              name={filter?.key}
              id={filter?.key}
              min={0}
              className={`${
                multiScreen ? 'text-sm' : 'text-md'
              } block w-full border-0 py-0 px-1 bg-transparent relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] focus:ring-0`}
              placeholder={filter?.filterable?.placeholder?.high ?? 'Max'}
              onChange={(e) => {
                const value = Number(e.target.value);
                const maxValueNum = Number(filter?.filterable?.minMax);

                dispatch({
                  type: 'UPDATE_FILTER_VALUES',
                  payload: {
                    updatedValue:
                      value > maxValueNum && !Number.isNaN(maxValueNum)
                        ? maxValueNum
                        : value ?? '',
                    key: filter?.key,
                    minMax: 'max',
                    group,
                  },
                });
                setMaxValue(
                  value > maxValueNum && !Number.isNaN(maxValueNum)
                    ? maxValueNum
                    : value ?? '',
                );
              }}
              value={maxValue || ''}
            />
          </div>
          {multiScreen ? (
            <label
              htmlFor="name"
              className={`${
                multiScreen ? 'range-label-multi' : ''
              } block text-xs font-medium text-[#70769B]  range-label`}
            >
              Max
            </label>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Range;
