import React, {useState, useEffect, useMemo, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import WatchlistTable from './WatchlistTable';
import 'react-datepicker/dist/react-datepicker.css';
import ScannerContext from '../../Context/ScannerContext';
import AppContext from '../../Context/AppContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const AddToWatchlist = ({watchlistData, handleAddToWatchlist}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [inputSuccess, setInputSuccess] = useState(false);
  const [inputSuccessMessage, setInputSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    setInputError('');
    setInputSuccessMessage('');
    setInputSuccess(false);
  };

  const isString = (value) => typeof value === 'string' || value instanceof String;

  const existsInWatchlist = (symbol) => {
    if (!watchlistData || !watchlistData?.data?.length) {
      return false;
    }
    const found = watchlistData?.data?.find(
      (item) =>
        isString(item?.Symbol?.value) &&
        isString(symbol) &&
        item?.Symbol?.value?.toLowerCase() === symbol.toLowerCase(),
    );
    return found;
  };

  const updateWatchlist = async (e) => {
    e.preventDefault();

    if (!inputValue) {
      setInputError('Please enter a ticker symbol.');
      return;
    }

    if (!isString(inputValue)) {
      setInputError('Please enter a valid ticker symbol.');
      return;
    }

    if (existsInWatchlist(inputValue)) {
      setInputError('Symbol already exists in your watchlist.');
      return;
    }

    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso');
    }
    if (!token) {
      setInputError('Please sign in or refresh page to add to your watchlist.');
      return;
    }
    setLoading(true);
    try {
      await handleAddToWatchlist(null, inputValue);
      setInputSuccessMessage(`Successfully added ${inputValue} to your watchlist.`);
      setInputSuccess(true);
      setInputValue('');
    } catch (err) {
      setInputError('There was an error adding the symbol to your watchlist.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputSuccess) {
      setTimeout(() => {
        setInputSuccessMessage('');
        setInputSuccess(false);
      }, 5000);
    }
  }, [inputSuccess]);

  return (
    <div className="pb-5">
      <form className="flex items-center">
        <div className="w-full max-w-xs">
          <label htmlFor="ticker" className="sr-only">
            Ticker
          </label>
          <input
            disabled={loading}
            onChange={onInputChange}
            value={inputValue}
            id="ticker"
            name="ticker"
            type="ticker"
            placeholder="AAPL"
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className="block w-full rounded-md border-0 py-1.5 px-1 text-[#70769B] placeholder:text-[#70769b] shadow-sm ring-transparent outline-none focus:ring-transparent text-sm leading-6"
          />
        </div>
        <button
          onClick={updateWatchlist}
          disabled={loading || !inputValue}
          type="submit"
          className="inline-flex items-center justify-center rounded-md text-[#70769B] bg-[#282A39] border-gray-300  px-3 py-2 text-sm font-semibold shadow-sm transition-all hover:bg-[#70769B] hover:text-[#282A39] focus-visible:outline focus-visible:outline-0  ml-3 mt-0 w-auto"
        >
          {loading ? <div className="watchlist-loader" /> : 'Add To Watchlist'}
        </button>
      </form>
      {inputError && <p className="mt-2 text-sm text-red-600">{inputError}</p>}
      <p className={`${inputSuccessMessage ? 'fadeIn' : 'fadeOut'} text-sm text-green-600`}>{inputSuccessMessage}</p>
    </div>
  );
};

const Watchlist = ({
  group,
  groupNumber,
  multiScreen,
  optionsColumns,
  optionsAllowed,
  lockedColumns,
  groupName,
  displayChartDisabledMessage,
  allowCustomInputWatchlist,
}) => {
  const [state] = useOutletContext();
  const {groupCollection} = state;
  const {activeWatchlist, handleAddToWatchlist} = useContext(AppContext);
  const [watchlistSettings, setWatchlistSettings] = useState({
    page: 0,
    orderBy: 'Symbol',
    order: 'asc',
    rowsPerPage: 100,
  });
  const [watchlistData, setWatchlistData] = useState({
    data: [],
    dataTypes: [],
  });

  const handleChangePage = (event, newPage) => {
    setWatchlistSettings({...watchlistSettings, page: newPage});
  };

  const handleChangeOrderBy = (event, property) => {
    const isAsc = watchlistSettings.orderBy === property && watchlistSettings.order === 'asc';
    setWatchlistSettings({
      ...watchlistSettings,
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setWatchlistSettings({
      ...watchlistSettings,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    if (!activeWatchlist || !groupCollection) {
      return;
    }
    const watchlistKeys = Object.keys(activeWatchlist ?? {});
    const allDB = allowCustomInputWatchlist?.watchlistDB;

    if (watchlistKeys?.length) {
      const wlDataTypes = watchlistKeys.reduce((acc, key) => {
        if (groupCollection?.[key] && Object.keys(groupCollection?.[key] ?? {}).length) {
          if (Object.keys(groupCollection[key]?.dataTypes ?? {}).length) {
            const mergedWithAcc = [...(acc ?? []), ...groupCollection[key].dataTypes];
            const arrayUniqueByKey = [...new Map(mergedWithAcc.map((item) => [item.key, item])).values()];
            return arrayUniqueByKey;
          }
          return acc;
        }
        const maybeDPLKeys = [
          'MTA_DPL',
          'MTA_DPL_Index',
          'MTA_DPL_NASDAQ100',
          'MTA_DPL_Favorite',
          'MTA_DPL_Favorite_NateG',
          'MTA_DPL_Favorite_DMan',
          'Profit Surge',
        ];
        const maybeMwKeys = ['M_W', 'M_W Dev'];
        if (allDB)  {
          maybeMwKeys.push(allDB);
        }
        let maybeKey = null;
        if (Object.keys(groupCollection ?? {}).find((k) => maybeDPLKeys.includes(k) || maybeMwKeys.includes(k))) {
          maybeKey = Object.keys(groupCollection ?? {}).find(
            (k) => maybeDPLKeys.includes(k) || maybeMwKeys.includes(k),
          );
        }

        if (maybeKey && groupCollection?.[maybeKey] && Object.keys(groupCollection?.[maybeKey] ?? {}).length) {
          if (Object.keys(groupCollection[maybeKey]?.dataTypes ?? {}).length) {
            const mergedWithAcc = [...(acc ?? []), ...groupCollection[maybeKey].dataTypes];
            const arrayUniqueByKey = [...new Map(mergedWithAcc.map((item) => [item.key, item])).values()];
            return arrayUniqueByKey;
          }
          return acc;
        }

        return acc;
      }, []);
      const wlData = watchlistKeys.reduce((acc, key) => {
        const symbolArr = activeWatchlist[key];
        if (!groupCollection?.[key] || !Object.keys(groupCollection?.[key] ?? {}).length) {
          if (allDB && Object.keys(groupCollection[allDB]?.data ?? {}).length) {
            const fromGroupCollection = symbolArr
              .map(
                (symbol) =>
                  ({...(groupCollection[allDB].data?.[symbol] ?? {Symbol: {value: symbol}}), parentGroup: allDB} ?? null),
              );
            return [...(acc ?? []), ...fromGroupCollection];
          }
          const parentGroupNotFound = symbolArr.map((symbol) => ({Symbol: {value: symbol}, parentGroup: null}));
          return [...(acc ?? []), ...parentGroupNotFound];
        }
        if (groupCollection?.[key] && Object.keys(groupCollection?.[key] ?? {}).length) {
          if (Object.keys(groupCollection[key]?.data ?? {}).length) {
            const fromGroupCollection = symbolArr
              .map(
                (symbol) =>
                  ({...(groupCollection[key].data?.[symbol] ?? {Symbol: {value: symbol}}), parentGroup: key} ?? null),
              )
              .filter((data) => data);
            return [...(acc ?? []), ...fromGroupCollection];
          }
          return acc;
        }
        return acc;
      }, []);

      if (JSON.stringify(wlData) === JSON.stringify(watchlistData)) return;
      setWatchlistData({data: wlData, dataTypes: wlDataTypes ?? []});
    }
  }, [groupCollection, activeWatchlist, activeWatchlist, groupName]);

  const contextValue = useMemo(
    () => ({
      group,
      groupNumber,
      multiScreen,
      optionsColumns,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
      handleChangePage,
    }),
    [
      group,
      groupNumber,
      multiScreen,
      optionsColumns,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
    ],
  );
  return (
    <ScannerContext.Provider value={contextValue}>
      <div className="scanner-wrap">
        {allowCustomInputWatchlist ? (        <div>
          <AddToWatchlist
            watchlistData={watchlistData}
            activeWatchlist={activeWatchlist}
            handleAddToWatchlist={handleAddToWatchlist}
          />
        </div>) : null}

        {Object.keys(activeWatchlist ?? {})?.length ? (
          <div className="scanner">
            <WatchlistTable
              tableData={watchlistData?.data ?? []}
              dataTypes={watchlistData?.dataTypes ?? []}
              orderBy={watchlistSettings?.orderBy}
              page={watchlistSettings?.page}
              order={watchlistSettings?.order}
              rowsPerPage={watchlistSettings?.rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeOrderBy={handleChangeOrderBy}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <div className="scanner"> Click the icon next to a Symbol to add to your watchlist. </div>
        )}
      </div>
    </ScannerContext.Provider>
  );
};
export default React.memo(Watchlist, (prev, next) => {
  const groupCollectionSame = JSON.stringify(prev?.dataGroupCollection) === JSON.stringify(next?.dataGroupCollection);
  const lockedColumnsSame = JSON.stringify(prev?.lockedColumns) === JSON.stringify(next?.lockedColumns);
  const optionsAllowedSame = JSON.stringify(prev?.optionsAllowed) === JSON.stringify(next?.optionsAllowed);
  const optionsColumnsSame = JSON.stringify(prev?.optionsColumns) === JSON.stringify(next?.optionsColumns);
  const showLockedModalSame = JSON.stringify(prev?.showLockedModal) === JSON.stringify(next?.showLockedModal);
  const watchlistSame = JSON.stringify(prev?.activeWatchlist) === JSON.stringify(next?.activeWatchlist);
  const displayChartDisabledMessageSame =
    JSON.stringify(prev?.displayChartDisabledMessage) === JSON.stringify(next?.displayChartDisabledMessage);
    const allowCustomInputWatchlistSame = JSON.stringify(prev?.allowCustomInputWatchlist) === JSON.stringify(next?.allowCustomInputWatchlist);
  return (
    lockedColumnsSame &&
    optionsAllowedSame &&
    optionsColumnsSame &&
    showLockedModalSame &&
    watchlistSame &&
    groupCollectionSame &&
    displayChartDisabledMessageSame &&
    allowCustomInputWatchlistSame
  );
  // return orderBySame && orderSame && pageSame && rowsPerPageSame && dateSame;
});

// export default Watchlist;
