/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Transition, Popover} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import Range from './Range';
import ScannerContext from '../../../Context/ScannerContext';

const MultiScreenRange = () => {
  const {group} = useContext(ScannerContext);
  const [groupFilters, setGroupFilters] = useState([]);

  useEffect(() => {
    if (!group?.searchValue?.filters.length) return;
    setGroupFilters(group?.searchValue?.filters.filter((filter) => filter && filter?.filterable?.type === 'range'));
  }, [group?.searchValue?.filters]);


  return groupFilters?.length ? (
    <div className="flex items-center justify-start">
      <Popover as="div" className="relative inline-block text-left">
        <div>
          <Popover.Button
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className="filter-label inline-flex justify-center w-full rounded-[50px] border-none  text-[#70769B] border-gray-300 shadow-sm bg-white text-md px-2 py-1 whitespace-nowrap font-medium  hover:bg-gray-50 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-transparent"
          >
            Range Filter
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Popover.Button>
        </div>

        <Transition
          key={null}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className=" w-auto origin-top-right absolute mt-2 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-4 py-2 gap-3 flex flex-col w-56 max-h-[224px] overflow-y-scroll">
              {groupFilters.map((filter) => (
                <Range
                  key={filter?.key ?? filter?.type}
                  filter={filter}
                />
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  ) : null;
};

export default MultiScreenRange;
