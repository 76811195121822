import React, {useState, useEffect, useContext} from 'react';
import {useOutletContext} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import Accordion from '../Accordion/Accordion';
import AllFilters from '../Filters/AllFilters';
import MultiScreenRange from '../Filters/Fields/MultiScreenRange';
import MultiSelectWithSearch from '../Filters/Fields/MultiSelectWithSearch';
import {ReactComponent as SearchSmIcon} from '../../Icons/SearchSm.svg';
import {join} from '../../Helpers/formatData';
import AppContext from '../../Context/AppContext';
import ScannerContext from '../../Context/ScannerContext';

const moment = require('moment');

const Header = ({currentDate}) => {
  const [state, dispatch, wsSocket] = useOutletContext();
  const {groupCollection, groups} = state;
  const {scannerLayout, updatingDate, setUpdatingDate} = useContext(AppContext);
  const {group, multiScreen, handleUpdateMultiSelect, hasFilters} = useContext(ScannerContext);
  const [searchValue, setSearchValue] = useState('');
  const [hasSearch, setHasSearch] = useState(false);
  const [searchFiltersArr, setSearchFilters] = useState([]);
  const [hasMultiselect, setHasMultiselect] = useState(false);
  const disableDays = (current) => {
    const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
    const day = moment(current).day();
    return day !== 0 && day !== 6 && current >= june;
  };

  const formatDate = (date) => {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) return new Date();
    const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
    const june = moment().set({year: 2022, month: 4, date: 31});
    const day = selectedToMomentDay.day();
    // If not weekend and after June 31st, return date
    if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
      return selectedToMomentDay.toDate();
    }
    return new Date();
  };

  const handleSingleDatePickerChange = (newDate) => {
    const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
    const today = join(new Date(), acc, '-');
    const {type: groupType, group: groupName} = group;
    const getGroup = {...group};
    getGroup.date = newDate;
    getGroup.page = 0;
    getGroup.isCurrentDate = newDate === today && getGroup?.showDateSelector;
    const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
    const groupToJoin = {
      group: `${groupName ? decodeURIComponent(groupName) : ''}`,
      date: newDate,
    };
    wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    dispatch({type: 'CHANGE_GROUP_DATE', payload: {newDate, group}});
  };

  const handleMultiGroupDatePickerChange = (newDate) => {
    if (!groups?.length || !newDate) return;
    groups.forEach((gr) => {
      const {type: groupType, group: groupName} = gr;
      if (!groupType || !groupName) return;
      // const getGroup = groupCollection?.[groupName] ? {...groupCollection[groupName]} : null;
      // if (!getGroup) return;

      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
      };
      wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    });
    dispatch({type: 'CHANGE_MULTI_GROUP_DATE', payload: {newDate, groupCollection}});
  };

  const handleDatePickerChange = (date) => {
    if (!group || !wsSocket) return;
    const newDate = moment(date).format('YYYY-MM-DD');
    if (setUpdatingDate) {
      setUpdatingDate(true);
    }
    if (scannerLayout === 'tabs-layout' || scannerLayout === 'mw-layout') {
      handleMultiGroupDatePickerChange(newDate);
    } else {
      handleSingleDatePickerChange(newDate);
    }
  };

  useEffect(() => {
    if (!group?.searchValue?.filters) return;
    const searchFilters = group?.searchValue?.filters.filter((filt) => filt?.filterable?.type === 'search');
    const multiSelectFilters = group?.searchValue?.filters.filter(
      (filt) => filt?.filterable?.type === 'multiselect' && filt?.type === 'string',
    );
    setHasSearch(searchFilters.length > 0);
    if (searchFilters?.length) {
      setSearchFilters(searchFilters);
    }
    setHasMultiselect(multiSelectFilters.length > 0);
  }, [group?.searchValue?.filters]);

  useEffect(() => {
    if (!Object.keys(groupCollection).length || !updatingDate) return;
    const groupCollectionKeys = Object.keys(groupCollection ?? {});
    const filteredKeys = groupCollectionKeys.filter((key) => {
      const gr = groupCollection[key];
      if (!gr) {
        return false;
      }
      if (!gr?.currentDate) {
        if (!gr?.sentDate) {
          return false;
        }
        if (gr?.sentDate && !gr?.recievedDate) {
          return false;
        }
        if (gr?.sentDate && gr?.recievedDate) {
          return gr?.sentDate !== gr?.recievedDate;
        }
      }
      return false;
    });
    if (!filteredKeys.length) {
      setUpdatingDate(false);
    }
  }, [groups, groupCollection]);

  return (
    <div className="mb-1 mx-auto group-header">
      <div className="flex justify-start gap-9 filter-wrapper">
        {hasSearch
          ? searchFiltersArr?.map((filter) => (
              <div key={filter?.key} className="flex items-center relative">
                <input
                  type="text"
                  className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] py-2 pl-9 "
                  placeholder="Filter Tickers"
                  style={{background: 'rgba(148, 196, 242, 0.1)'}}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_FILTER_VALUES',
                      payload: {
                        updatedValue: e.target.value,
                        key: filter?.key,
                        minMax: null,
                        group,
                      },
                    });
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue || ''}
                  name={filter?.key}
                  id={filter?.key}
                />
                <SearchSmIcon className="text-[#70769B] absolute left-4 top-1/2 -translate-y-1/2" />
              </div>
            ))
          : null}
        <div className="flex gap-7 date-pick-wrap">
          {updatingDate ? (
            <div className="lds-ring-date">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : group?.selectedDate && group?.showDateSelector ? (
            <div className="flex items-center">
              <DatePicker
                filterDate={disableDays}
                selected={formatDate(currentDate ?? group?.selectedDate ?? null)}
                onChange={handleDatePickerChange}
                customInput={<CustomInputDate />}
              />
            </div>
          ) : null}
        </div>
        <div className="flex items-center gap-4 pr-5 justify-end flex-1">
          <div className="flex  flex-wrap lg:flex-row filter-btn my-1 filter-row gap-4 mx-auto group-header text-[14px]">
            {hasMultiselect ? <MultiSelectWithSearch /> : null}
          </div>
          {hasFilters() ? (
            <Accordion title="Filter Options">
              <div className="flex items-end flex-col flex-wrap lg:flex-row filter-btn my-1 filter-row gap-4 mx-auto group-header">
                {group?.searchValue?.filters?.filter((filt) => filt?.filterable?.type !== 'search')?.length ? (
                  <AllFilters />
                ) : null}

                {group?.searchValue?.filters?.filter((filter) => filter && filter?.filterable?.type === 'range')
                  .length && multiScreen ? (
                  <div>
                    <MultiScreenRange />
                  </div>
                ) : null}

                {group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length) ||
                group?.searchValue?.additionalFilters?.length ||
                group?.searchValue?.bearBullFilters?.length ||
                group?.showBearBullFilter ? (
                  <div className="flex flex-col w-full gap-4">
                    <FilterDropdown
                      group={group}
                      handleUpdateMultiSelect={handleUpdateMultiSelect}
                      additionalFields={[
                        ...(group?.searchValue?.additionalFilters ?? []),
                        ...(group?.searchValue?.boolFilters ?? []),
                      ]}
                      boolFilters={group?.searchValue?.filters?.filter((filter) => filter.type === 'boolean') ?? []}
                      bearBullFilters={group?.searchValue?.bearBullFilters ?? []}
                      showBearBullFilter={group?.showBearBullFilter}
                      activeFilter={group?.activeFilter}
                      dispatch={dispatch}
                      multiScreen={multiScreen}
                    />
                  </div>
                ) : null}
              </div>
            </Accordion>
          ) : null}
          <div className="flex justify-between mx-auto w-full delay-info text-right">
            {!process.env?.REACT_APP_LIVE_DATA ? (
              <p className="delay-info w-full ">
                <small>15 Minute Delay</small>
              </p>
            ) : null}
            {/* {!isTradingHours() ? (
                <p className="after-market-hours w-full text-right">
                  <small>Data represents values from prior business day&apos;s close</small>
                </p>
              ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

// export default React.memo(Header, (prev, next) => {
//   const pageSame = prev.page === next.page;
//   const countSame = prev.count === next.count;
//   const rowsPerPageSame = prev.rowsPerPage === next.rowsPerPage;
//   const filtersSame = JSON.stringify(prev.filters) === JSON.stringify(next.filters);
//   const dateSame =
//     prev?.group?.selectedDate === next?.group?.selectedDate &&
//     prev?.group?.showDateSelector === next?.group?.showDateSelector;
//   return pageSame && countSame && rowsPerPageSame && filtersSame && dateSame;
// });

export default Header;
